import { StaticImage } from "gatsby-plugin-image";
import React from "react";

export default function Youtube() {
  return (
    <div className="instagram-logo-container">
      <a
        href="https://www.youtube.com/channel/UCbtmjIQdwQxB5DNIrsAfIkQ/featured"
        target="_blank"
        rel="noreferrer"
      >
        <StaticImage
          className="instagram-logo"
          src="../images/logo-youtube.png"
          alt="The youtube logo"
        />
      </a>
    </div>
  );
}
