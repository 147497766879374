import React, { useState } from "react";
import { Link } from "gatsby";
import addToMailchimp from "gatsby-plugin-mailchimp";
import Twitter from "../components/twitter";
import Youtube from "../components/youtube";
import Insta from "../components/insta";
import "../App.css";

import useWindowSize from "../hooks/useWindowSize";

export default function Layout({ children }) {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const { width } = useWindowSize();

  return (
    <div style={{ margin: `0 auto` }}>
      <title>Outer Inner World</title>
      {width > 800 ? (
        <div className="nav">
          <Link className="nav-element" activeStyle={{ color: "green" }} to="/">
            <h2 style={{ fontWeight: "normal" }}>Outer Inner World</h2>
          </Link>
          <Link
            className="nav-element"
            activeStyle={{ color: "green" }}
            to="/blog/bohm-dialogue"
          >
            Bohmian Dialogue
          </Link>
          <Link
            className="nav-element"
            activeStyle={{ color: "green" }}
            to="/blog/about"
          >
            about
          </Link>
        </div>
      ) : (
        <div className="nav">
          <Link className="nav-element" activeStyle={{ color: "green" }} to="/">
            <h2 style={{ fontWeight: "normal" }}>OIW</h2>
          </Link>
          <Link
            className="nav-element"
            activeStyle={{ color: "green" }}
            to="/blog/bohm-dialogue"
          >
            BD
          </Link>
          <Link
            className="nav-element"
            activeStyle={{ color: "green" }}
            to="/blog/about"
          >
            a
          </Link>
        </div>
      )}

      {children}
      <div className="footer">
        <form className="subscribe-form">
          <input
            className="subscribe-input"
            type="text"
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
            }}
            placeholder=" Your email address? :)"
          ></input>
          <button
            className="subscribe-button"
            type="submit"
            onClick={(e) => {
              e.preventDefault();
              addToMailchimp(email).then((res) => setMessage(res.msg));
              setEmail("");
            }}
          >
            Subscribe
          </button>
        </form>
        <div className="subscribe-response">{message}</div>
        <div className="social-logos-container">
          <Twitter />
          <Insta />
          <Youtube />
        </div>
      </div>
    </div>
  );
}
