import { StaticImage } from "gatsby-plugin-image";
import React from "react";

export default function Twitter() {
  return (
    <div className="instagram-logo-container">
      <a
        href="https://twitter.com/lostonthevine"
        target="_blank"
        rel="noreferrer"
      >
        <StaticImage
          className="instagram-logo"
          src="../images/logo-twitter.png"
          alt="The twitter logo"
        />
      </a>
    </div>
  );
}
