import { StaticImage } from "gatsby-plugin-image";
import React from "react";

export default function Insta() {
  return (
    <div className="instagram-logo-container">
      <a
        href="https://www.instagram.com/jackreidsong/"
        target="_blank"
        rel="noreferrer"
      >
        <StaticImage
          className="instagram-logo"
          src="../images/logo-instagram.png"
          alt="The instagram logo"
        />
      </a>
    </div>
  );
}